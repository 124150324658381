export const GRID_VIEW = {
  WEEK: 'week',
  MONTH: 'month'
}

export const MODULES = {
  TURF: 'Turf',
  MEMBER: 'Member',
  ACADEMY: 'Academy'
}


export const PHONE_REGEX = /^[6-9]\d{9}$/

export const EMAIL_ID_REGEX = /^([a-zA-Z0-9._%-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,})$/

export const TURF_BOOKING_MESSAGE = `
NAME: {{NAME}}%0a
MOBILE: {{MOBILE}}%0a
DATE: {{DATE}}%0a
TIME: {{TIME}}%0a
TURF NO.: {{TURF_NO}}%0a
BOOKING STATUS: {{BOOKING_STATUS}}%0a
PAYMENT: {{PAYMENT_STATUS}}%0a
%0a
 1. Only stumps will be provided (no bat or ball)%0a
 2. 1000/hour, payment will be received in cash only%0a
 3. Speaker, Mic or any kind of music strictly not allowed
`;

export const ACADEMY_MESSAGE = `
Hi {{NAME}},%0a
%0a
This is a confirmation message that we’ve received your {{SPORT}} Coaching Fees.%0a
Fees Details%0a%0a
Batch Time: {{BATCH_TIME}}%0a
Date: From {{DATE}}%0a
Amount: {{FEES}}%0a
Paid on: {{PAID_DATE}}%0a
%0a
Thanks%0a
CB Patel Cricket and Football Academy%0a
7722848484, 9374416143
`;

export const MEMBER_MESSAGE = `
Hi {{NAME}},%0a
%0a
This is a confirmation message that we’ve received your Membership Fees for Turf Booking.%0a
%0a
Membership Details%0a
%0a
Duration: {{START_DATE}} to {{END_DATE}}%0a
Amount: {{FEES}}%0a
Total Hours: {{HOURS}}%0a
Paid on: {{PAID_DATE}}%0a
%0a
%0a
Thanks%0a
CB Patel Cricket and Football Academy%0a
7722838383%0a
`;