import { useState } from 'react';
import './App.css';
import { HashRouter as Router } from 'react-router-dom';
import Container from './pages/container';
import Login from './pages/login/login';

function App() {
  const [userLoggedin, setUserLoggedin] = useState(!!localStorage.getItem('CBisLoggedIn'));

  return (
    <div className="App">
      {userLoggedin && (
        <Router>
          <Container logout={() => window.location.reload('/')} />
        </Router>
      )}
      {!userLoggedin && <Login userLoggedIn={() => setUserLoggedin(true)} />}
    </div>
  );
}

export default App;
