import axios from 'axios';

const SERVER_HIT = axios.create({
	// baseURL: 'http://localhost/cb-patel-academy-admin/API/index.php',
	baseURL: 'https://cbpatel-academy.miteshvanatwala.com/API/index.php',
	timeout: 8000,
  headers: {
    'Content-Type': 'application/json; charset=UTF-8',
    dataType: "json"
  },
});

SERVER_HIT.interceptors.request.use(async config => {
  try {
      config.headers.ssnid = window.localStorage.getItem('CBssnid')
      return config
  } catch (err) {}
});

SERVER_HIT.interceptors.response.use(
  res => res,
  error => {
    if (error.response.status === 401) {
        throw error.response.status;
    }
    return Promise.reject(error.response.data)
})

export { SERVER_HIT }