import { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import { SERVER_HIT } from '../util/API';
import { Col, Row } from 'react-bootstrap';
import moment from 'moment';
import { get } from 'lodash';
import { MEMBER_MESSAGE } from '../constants';
import { AiOutlineWhatsApp } from 'react-icons/ai';

function Membership({
  memberDetail,
  showModal,
  hideModal
}) {
  const [ startDate, setStartDate ] = useState('');
  const [ endDate, setEndDate ] = useState('');
  const [ totalCredit, setTotalCredit ] = useState(1);
  const [ totalFees, setTotalFees ] = useState(0);
  const [ loading, setLoading ] = useState(false);
  const [ validated, setValidated ] = useState(false);
  const [ errorMessage, setErrorMessage ] = useState('');
  const [ membershipList, setMembershipList ] = useState([]);

  const readOnly = get(memberDetail, 'readOnly', false);
  const today = moment().format("YYYY-MM-DD");
  useEffect(() => {
    if (showModal) {
      if (memberDetail.membership_id !== '') {
        setStartDate(moment(memberDetail.start_date).format("YYYY-MM-DD"));
        setEndDate(moment(memberDetail.end_date).format("YYYY-MM-DD"));
        setTotalCredit(parseInt(memberDetail.total_credit) || 1);
        setTotalFees(parseInt(memberDetail.total_fees) || 0);
      }
      getMemberShipData();
    } else {
      setStartDate(null);
      setEndDate(null);
      setTotalCredit(1);
      setTotalFees(0);
      setMembershipList([]);
    }
  }, [ showModal ]);

  const getMemberShipData = async () => {
    const { status, data } = await SERVER_HIT.get(`?module=member&operation=membership_list_of_member&member_id=${memberDetail.member_id}`, {});
    if (status === 200) {
      setMembershipList(data);
    }
  }

  const handleSubmit = async (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    } else {
      setErrorMessage('');
      setValidated(true);
      if (startDate && endDate && totalCredit) {
        setLoading(true);
        const { status, data } = await SERVER_HIT.post(`?module=member&operation=${memberDetail.membership_id === '' ? 'add_membership' : 'edit_membership' }`, {
          member_id: memberDetail.member_id,
          membership_id: memberDetail.membership_id,
          start_date: startDate,
          end_date: endDate,
          total_credit: totalCredit,
          total_fees: totalFees,
        });
        setLoading(false);
        if (status === 200) {
          if (data.message === 'Data inserted' || data.message === 'Data updated') {
            getMemberShipData();
          } else {
            setErrorMessage(data.message);
          }
        }
      }
    }
  };

  const prepareWhatsappMessage = () => {
    console.log(memberDetail)
    var message = MEMBER_MESSAGE;
    message = message.replace("{{NAME}}", get(memberDetail, 'member_name', ''));
    message = message.replace("{{START_DATE}}", moment(get(memberDetail, 'start_date', '')).format("DD-M-YY"));
    message = message.replace("{{END_DATE}}", moment(get(memberDetail, 'end_date', '')).format("DD-M-YY"));
    message = message.replace("{{FEES}}", get(memberDetail, 'total_fees', ''));
    message = message.replace("{{HOURS}}", get(memberDetail, 'total_credit', ''));
    message = message.replace("{{PAID_DATE}}", moment(get(memberDetail, 'paid_on', '')).format("DD-M-YY"));
    return message;
  }

  return (
    <>
      <Modal show={showModal} onHide={hideModal} backdrop="static" keyboard={false} size="xl">
        <Modal.Header closeButton>
          <Modal.Title>{readOnly ? 'View' : 'Manage'} Membership</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col md={4}>
              <Form validated={validated} onSubmit={handleSubmit}>
                <Form.Group className="mb-3">
                  <Form.Label>Start Date</Form.Label>
                  <Form.Control
                    type="date"
                    min={memberDetail.membership_id != '' ? startDate : today}
                    autoFocus
                    value={startDate}
                    onChange={(event) => setStartDate(event.target.value)}
                    required
                    disabled={readOnly}
                  />
                  <Form.Control.Feedback type="invalid">Please enter start date</Form.Control.Feedback>
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>End Date</Form.Label>
                  <Form.Control
                    type="date"
                    min={today}
                    required
                    autoFocus
                    value={endDate}
                    onChange={(event) => setEndDate(event.target.value)}
                    disabled={readOnly}
                  />
                  <Form.Control.Feedback type="invalid">Please enter end date</Form.Control.Feedback>
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Label>Total Hours</Form.Label>
                  <Form.Control
                    type="number"
                    min="1"
                    required
                    autoFocus
                    value={totalCredit}
                    onChange={(event) => setTotalCredit(event.target.value)}
                    disabled={readOnly}
                  />
                  <Form.Control.Feedback type="invalid">Please enter end date</Form.Control.Feedback>
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Label>Total Fees</Form.Label>
                  <Form.Control
                    type="number"
                    min="1"
                    required
                    autoFocus
                    value={totalFees}
                    onChange={(event) => setTotalFees(event.target.value)}
                    disabled={readOnly}
                  />
                  <Form.Control.Feedback type="invalid">Please enter end date</Form.Control.Feedback>
                </Form.Group>

                <div className='error text-center text-error'>
                  {errorMessage}
                </div>

                <div className='text-end'>
                  {/* <Button variant="secondary" onClick={hideModal} className='me-2 mt-3'>
                    Close
                  </Button> */}
                  <Button variant="primary" onClick={handleSubmit} className='mt-2' disabled={readOnly}>
                    { loading ? 'Saving...' : 'Save'}
                  </Button>
                </div>
              </Form>
            </Col>
            <Col>
              <table className="table table-bordered table-hover">
                <thead>
                  <tr>
                    <th scope="col" className="text-center">Start Date</th>
                    <th scope="col" className="text-center">End Date</th>
                    <th scope="col" className="text-center">Hours</th>
                    <th scope="col" className="text-center">Fees Paid</th>
                  </tr>
                </thead>
                {
                  membershipList.length > 0 && (
                    <tbody>
                      {
                        membershipList.map(({ start_date, end_date, total_credit, total_fees }) => {
                          return (
                            <tr>
                              <td scope="col" className="text-center">{moment(start_date).format("DD MMM, YYYY")}</td>
                              <td scope="col" className="text-center">{moment(end_date).format("DD MMM, YYYY")}</td>
                              <td scope="col" className="text-center">{total_credit}</td>
                              <td scope="col" className="text-center">{total_fees}</td>
                            </tr>
                          )
                        })
                      }
                    </tbody>
                  )
                }
              </table>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          {
            get(memberDetail, 'contact_number', '') && (
              <Button variant="success" onClick={() => window.open(`https://api.whatsapp.com/send?phone=91${get(memberDetail, 'contact_number', '')}&text=${prepareWhatsappMessage()}`)}>
                <AiOutlineWhatsApp size={20} style={{ verticalAlign: 'sub'}} />
              </Button>
            )
          }
          <Button variant="secondary" onClick={hideModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default Membership;