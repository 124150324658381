import { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import { SERVER_HIT } from '../util/API';
import { useDispatch } from 'react-redux';
import { setMemberList } from '../store/academySlice';
import { get } from 'lodash';

function AddBooker({
  memberDetail,
  showModal,
  hideModal
}) {
  const [ bookerName, setBookerName ] = useState('');
  const [ bookerContactNumber, setBookerContactNumber ] = useState('');
  const [ loading, setLoading ] = useState(false);
  const [ validated, setValidated ] = useState(false);
  const [ errorMessage, setErrorMessage ] = useState('');
  const dispatch = useDispatch();

  useEffect(() => {
    if (showModal) {
      setBookerName(memberDetail?.member_name || '');
      setBookerContactNumber(memberDetail?.contact_number || '');
    }
  }, [ showModal ])

  const handleSubmit = async (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    } else {
      setErrorMessage('');
      setValidated(true);
      if (bookerName && bookerContactNumber) {
        setLoading(true);
        const { status, data } = await SERVER_HIT.post(`?module=member&operation=${get(memberDetail, 'member_id',  '') === '' ? 'add_member' : 'update_member' }`, {
          member_id: get(memberDetail, 'member_id',  ''),
          member_name: bookerName,
          contact_number: bookerContactNumber,
        });
        setLoading(false);
        if (status === 200) {
          if (data.message === 'Data inserted' || data.message === 'Data updated') {
            const { status, data } = await SERVER_HIT.get('?module=member&operation=member_list', {});
            if (status === 200) {
              dispatch(setMemberList(data));
            }
            hideModal(true);
          } else {
            setErrorMessage(data.message);
          }
        }
      }
    }
  };

  return (
    <>
      <Modal show={showModal} onHide={hideModal} backdrop="static" keyboard={false}>
        <Modal.Header closeButton>
          <Modal.Title>Add Member</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form validated={validated} onSubmit={handleSubmit}>
            <Form.Group className="mb-3">
              <Form.Label>Name</Form.Label>
              <Form.Control
                type="text"
                autoFocus
                value={bookerName}
                onChange={(event) => setBookerName(event.target.value)}
                required
              />
              <Form.Control.Feedback type="invalid">Please provide name.</Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Contact Number</Form.Label>
              <Form.Control
                type="number"
                required
                value={bookerContactNumber}
                onChange={(event) => setBookerContactNumber(event.target.value)}
              />
              <Form.Control.Feedback type="invalid">Please provide contact number.</Form.Control.Feedback>
            </Form.Group>

            <div className='error text-center text-error'>
              {errorMessage}
            </div>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={hideModal}>
            Close
          </Button>
          <Button variant="primary" onClick={handleSubmit}>
            { loading ? 'Saving...' : 'Save'}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default AddBooker;