import React, { useEffect, useState } from "react";
import { Button, ButtonGroup, Col, OverlayTrigger, Popover, Row } from "react-bootstrap";
import { GRID_VIEW, TURF_BOOKING_MESSAGE } from "../constants";
import moment from "moment";
import { find, first, get, last } from "lodash";
import { FiMessageSquare, FiPlus } from "react-icons/fi";
import AddTurfBooking from "../components/addTurfBooking";
import { useSelector } from 'react-redux'
import { SERVER_HIT } from "../util/API";
import { BiSolidDownArrowAlt, BiSolidUpArrowAlt } from "react-icons/bi";

const TurfBooking = () => {
  const [ pageTitle, setPageTitle ] = useState('Week');
  const [ gridView, setGridView ] = useState(GRID_VIEW.WEEK);
  const [ dateList, setDateList ] = useState([]);
  const [ bookingList, setTurfBookingList ] = useState([]);
  const [ showAddTurfBookingModal, setAddTurfBookingModal ] = useState(false);
  const { turfList, turfHours, memberList } = useSelector((state) => state.academy);
  const [ addTurfBookingDetails, setAddTurfBookingDetails ] = useState({
    date: '',
    group_code: '',
    turf: '',
    member_id: '',
    payment_received: '',
    total_payment: '',
    turf_booking_id: '',
    turf_hour_id: '',
    turf_id: '',
  });
  const [ startDate, setStartDate ] = useState(moment());

  useEffect(() => {
    const days = [];
    const start = moment(startDate).startOf(gridView === GRID_VIEW.WEEK ? 'isoWeek' : 'month');
    const end = moment(startDate).endOf(gridView === GRID_VIEW.WEEK ? 'isoWeek' : 'month');

    var day = start;
    while (day <= end) {
      days.push({
        // key: day.format('D_MM_YYYY'),
        key: day.format('YYYY-MM-DD'),
        value: day.format('D MMMM YYYY')
      });
      day = day.clone().add(1, 'd');
    }
    setDateList(days);

    setPageTitle(`
      ${gridView === GRID_VIEW.WEEK ? 'Week' : 'Month'} 
      (${ first(days).value } to ${ last(days).value })
    `);

    getBookings();
  }, [gridView, startDate]);

  useEffect(() => {
    setTimeout(() => {
      if (document.querySelector('.grid-header-right-container') !== null && document.getElementById(`date-${moment().format('YYYY-MM-DD')}`) !== null) {
        document.querySelector('.grid-header-right-container').scrollTop = document.getElementById(`date-${moment().format('YYYY-MM-DD')}`).offsetTop - 235;
      }
    }, 1000);
  }, []);

  const NoEvent = (date, hour, turf) => {
    return (
      <div className="add-btn" onClick={() => {
        setAddTurfBookingDetails({
          date,
          turf_hour_id: hour,
          turf_id: turf
        })
        setAddTurfBookingModal(true)
      }}>
        <FiPlus className="fs-6 mt-3" />
      </div>
    )
  }

  const paymentColor = (paymentReceived, totalPayment) => {
    let className = '';
    if (paymentReceived === 0) className = 'red';
    else if (paymentReceived === totalPayment) className = 'green';
    return className;
  }
  
  const popover = (note) => (
    <Popover id="popover-basic">
      <Popover.Header as="h3">Note</Popover.Header>
      <Popover.Body>
        {note}
      </Popover.Body>
    </Popover>
  );

  const popoverBookingInfo = (memberDetail, paymentReceived, totalPayment, hourIds) => (
    <Popover id="popover-basic" rootCloseEvent='mousedown'>
      <Popover.Header as="h3">{get(memberDetail, 'member_name', '')}</Popover.Header>
      <Popover.Body>
        <div className="name">
          {/* { get(booking, 'note', '') !== '' && (
              <OverlayTrigger trigger="focus" placement="right" overlay={popover(get(booking, 'note', ''))}>
                <Button variant="link" style={{ padding: 0, float: 'right' }}>
                  <FiMessageSquare className="note" />
                </Button>
              </OverlayTrigger>
            )
          } */}
        </div>
        {/* <div className="phonenumber" onClick={() => window.open(`https://api.whatsapp.com/send?phone=91${get(memberDetail, 'contact_number', '')}&text=${prepareWhatsappMessage(memberDetail, booking)}`)}>
          {get(memberDetail, 'contact_number', '')}
        </div> */}
        <div className="text-center px-1">
          {/* { get(booking, 'is_member', 0) === 1 && <span className="member">M</span> } */}
          <span className={`p-1 ${paymentColor(paymentReceived, totalPayment)}`}>{paymentReceived} / {totalPayment}</span>
        </div>
      </Popover.Body>
    </Popover>
  );

  const openEditBooking = (booking) => {
    setAddTurfBookingDetails(booking)
    setAddTurfBookingModal(true)
  }

  const prepareWhatsappMessage = (memberDetail, booking) => {
    const paymentReceived = get(booking, 'payment_received', 0);
    const totalPayment = get(booking, 'total_payment', 0);
    const hourIds = get(booking, 'turf_hour_id', '').split(',');
    var hours = '';
    if (hourIds.length === 1) {
      hours = get(turfHours.find(({ hour_id }) => hour_id === hourIds[0]), 'value');
    } else {
      const startHour = get(turfHours.find(({ hour_id }) => hour_id === hourIds[0]), 'value', ['','']);
      const endHour = get(turfHours.find(({ hour_id }) => hour_id === hourIds[hourIds.length-1]), 'value', ['','']);
      hours = `${startHour.split('-')[0]} - ${endHour.split('-')[1]}`;
    }
    var message = TURF_BOOKING_MESSAGE;
    message = message.replace("{{NAME}}", get(memberDetail, 'member_name', ''))
    message = message.replace("{{MOBILE}}", get(memberDetail, 'contact_number', ''))
    message = message.replace("{{DATE}}", moment(get(booking, 'date', '')).format('DD-M-YY'))
    message = message.replace("{{TIME}}", hours)
    message = message.replace("{{TURF_NO}}", get(booking, 'turf_id', 'N/A'))
    message = message.replace("{{BOOKING_STATUS}}", 'CONFIRMED')
    message = message.replace("{{PAYMENT_STATUS}}", `${paymentReceived} RECEIVED / ${totalPayment-paymentReceived} PENDING`)
    return message;
  }

  const event = (booking) => {
    const memberDetail = find(memberList, { member_id: booking.member_id})
    const paymentReceived = get(booking, 'payment_received', 0);
    const totalPayment = get(booking, 'total_payment', 0);
    const hourIds = get(booking, 'turf_hour_id', '').split(',');
    return (
      <div
        className="turf-booking"
        style={{ width: `${(90 * hourIds.length) + ((hourIds.length-1) * 12)}px` }}
      >
        <div className="name">
          <span className="cursor-pointer" onClick={() => openEditBooking(booking)}>{get(memberDetail, 'member_name', '')}</span>
          {/* { get(booking, 'note', '') !== '' && (
              <OverlayTrigger trigger="focus" placement="right" overlay={popover(get(booking, 'note', ''))}>
                <Button variant="link" style={{ padding: 0, float: 'right' }}>
                  <FiMessageSquare className="note" />
                </Button>
              </OverlayTrigger>
            )
          } */}
        </div>
        {/* <div className="phonenumber" onClick={() => window.open(`https://api.whatsapp.com/send?phone=91${get(memberDetail, 'contact_number', '')}&text=${prepareWhatsappMessage(memberDetail, booking)}`)}>
          {get(memberDetail, 'contact_number', '')}
        </div> */}
        <div className="body text-end px-1">
          { get(booking, 'is_member', 0) === 1 && <span className="member">M</span> }
          <span className={`payment p-1 ${paymentColor(paymentReceived, totalPayment)}`}>{paymentReceived} / {totalPayment}</span>
        </div>
      </div>
    )
  }

  const getBookings = async () => {
    const start = startDate.startOf(gridView === GRID_VIEW.WEEK ? 'isoWeek' : 'month').format('YYYY-MM-DD');
    const end = startDate.endOf(gridView === GRID_VIEW.WEEK ? 'isoWeek' : 'month').format('YYYY-MM-DD');

    const { status, data } = await SERVER_HIT.get(`?module=turf&operation=get_turf_bookings&start_date=${start}&end_date=${end}`, {});
    if (status === 200) {
      setTurfBookingList(data);
    }
  }

  const bookingExist = (date, hour_id, turf_id) => {
    let isExist = false;
    if (bookingList) {
      for (let ind=0, len=bookingList.length; ind<len; ind++) {
        const hours = bookingList[ind]['turf_hour_id'].split(',');
        if (bookingList[ind]['date'] === date && hours[0] == hour_id && bookingList[ind]['turf_id'] === turf_id) {
          isExist = true;
          return event(bookingList[ind]);
        }
      }
    }

    if (!isExist) return NoEvent(date, hour_id, turf_id);
  }

  const gotoPrevious = () => {
    setStartDate(moment(startDate).add(dateList.length * -1, 'days'));
    console.log(moment(startDate).add(dateList.length * -1, 'days'));
  }

  const gotoNext = () => {
    setStartDate(moment(startDate).add(dateList.length, 'days'));
    console.log(moment(startDate).add(dateList.length, 'days'));
  }

  return (
    <>
      <Row className="px-1 py-1">
        <Col className="col-8 fs-5 pt-1 fb">
          <b>{pageTitle}</b>
        </Col>

        <Col className="col-4 text-end pb-1">
          <ButtonGroup size="sm" className='displayInlineBlock prod-selection'>
            <Button size="xs" variant="outline-primary" onClick={() => gotoPrevious()}>
              <BiSolidUpArrowAlt className="prev-next" />
            </Button>
            <Button size="xs" variant="outline-primary" onClick={() => gotoNext()}>
              <BiSolidDownArrowAlt className="prev-next" />
            </Button>
          </ButtonGroup>
          <ButtonGroup size="sm" className='displayInlineBlock prod-selection'>
            <Button variant="outline-primary" active={gridView === GRID_VIEW.WEEK} onClick={() => setGridView(GRID_VIEW.WEEK)}>Week</Button>
            <Button variant="outline-primary" active={gridView === GRID_VIEW.MONTH} onClick={() => setGridView(GRID_VIEW.MONTH)}>Month</Button>
          </ButtonGroup>
        </Col>
      </Row>
      <div>
        <div className="border grid-header-right-container fw-bold">
          <div className="grid-hour-row-container sticky-top">
            <div className="grid-header-hour date-turf-cell cell-border sticky-left">Date & Turf</div>
            {
              turfHours.map(({key, value}) => {
                return (
                  <div className="cell-border grid-header-hour" key={`head_hours${key}`}>
                    {value}
                  </div>
                )
              })
            }
          </div>

          {
            dateList.map(({key, value}) => {
              return (
                <>
                  <div className="grid-hour-row-container">
                    <div className="date-turf-cell cell-border sticky-left date-turf-first-cell" id={`date-${key}`}>{value}</div>
                    <div className={`cell-border grid-date-row date-turf-second-cell`}></div>
                  </div>

                  {
                    turfList.map(({turf_id: turfKey, turf_name: turfValue}) => {
                      return (
                        <div className="grid-hour-row-container" key={`${key}_${turfKey}`}>
                          <div className="grid-turf-hour date-turf-cell cell-border turf-name sticky-left">{turfValue}</div>
                          {
                            turfHours.map(({hour_id, key: hour}) => {
                              return (
                                <div className={`cell-border grid-turf-hour ${hour} turf_${turfKey}`} key={`${turfKey}_${hour}`}>
                                  {bookingExist(key, hour_id, turfKey)}
                                </div>
                              )
                            })
                          }
                        </div>
                      )
                    })
                  }
                </>
              )
            })
          }
        </div>
      </div>
      <AddTurfBooking
        showModal={showAddTurfBookingModal}
        handleShow={(refresh) => {
          setAddTurfBookingModal(false);
          if (refresh) getBookings();
        }}
        addTurfBookingDetails={addTurfBookingDetails}
      />
    </>
  );
};

export default TurfBooking;
