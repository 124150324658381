import React, { useEffect, useState } from 'react';
import { Button, ButtonGroup, Col, Row } from 'react-bootstrap';
import {
  Routes,
  Route,
  useNavigate
} from "react-router-dom";
import TurfBooking from './turfBooking';
import { SERVER_HIT } from '../util/API';
import { useSelector, useDispatch } from 'react-redux'
import { setTurfList, setTurfHours, setMemberList, setSchoolList, setBatchList, setStandardList, setSportList } from '../store/academySlice';
import { MODULES } from '../constants';
import Members from './members';
import PromptDialog from '../components/promptDialog';
import Academy from './academy';

const Container = ({
  logout
}) => {
  const navigate = useNavigate()
  const dispatch = useDispatch();
  const [ selectedModule, setSelectedModule ] = useState('');
  const [ username ] = useState(localStorage.getItem('CBusername'));
  const [ showLogoutDialog, setLogoutDialog ] = useState(false);

  useEffect(() => {
    checkSessionExist();
    switch (window.location.hash) {
      case '#/turf/booking':
        setSelectedModule(MODULES.TURF);
        navigate('/turf/booking');
        break;
      
      case '#/academy':
        setSelectedModule(MODULES.ACADEMY)
        navigate('/academy');
        break;
      
      case '#/members':
        setSelectedModule(MODULES.MEMBER)
        navigate('/members');
        break;
    
      default:
        setSelectedModule(MODULES.TURF);
        navigate('/turf/booking');
        break;
    }
  }, []);

  const getTurfList = async () => {
    const { status, data } = await SERVER_HIT.get('?module=turf&operation=turfs', {});
    if (status === 200) {
      dispatch(setTurfList(data));
    }
  }

  const getTurfHours = async () => {
    const { status, data } = await SERVER_HIT.get('?module=turf&operation=turf_hours', {});
    if (status === 200) {
      dispatch(setTurfHours(data));
    }
  }

  const getMemberList = async () => {
    const { status, data } = await SERVER_HIT.get('?module=member&operation=member_list', {});
    if (status === 200) {
      dispatch(setMemberList(data));
    }
  }

  const getSchoolList = async () => {
    const { status, data } = await SERVER_HIT.get('?module=academy&operation=school_list', {});
    if (status === 200) {
      dispatch(setSchoolList(data));
    }
  }
  
  const getStandardList = async () => {
    const { status, data } = await SERVER_HIT.get('?module=academy&operation=standard_list', {});
    if (status === 200) {
      dispatch(setStandardList(data));
    }
  }
  
  const getBatchList = async () => {
    const { status, data } = await SERVER_HIT.get('?module=academy&operation=batch_list', {});
    if (status === 200) {
      dispatch(setBatchList(data));
    }
  }

  const getSportList = async () => {
    const { status, data } = await SERVER_HIT.get('?module=academy&operation=sport_list', {});
    if (status === 200) {
      dispatch(setSportList(data));
    }
  }

  const checkSessionExist = async () => {
    const { status, data } = await SERVER_HIT.post('?module=user&operation=check_session_exist', {});
    if (status === 200) {
      if (data.code === 'session_not_exist') {
        localStorage.removeItem('CBisLoggedIn');
        logout();
      } else {
        getTurfList();
        getTurfHours();
        getMemberList();
        getBatchList();
        getSchoolList();
        getStandardList();
        getSportList();
      }
    }
  }

  const logoutPrompt = async () => {
    const { status, data } = await SERVER_HIT.post('?module=user&operation=logout', {});
    if (status === 200) {
      if (data === 'session_expired') {
        localStorage.removeItem('CBisLoggedIn');
        logout();
      }
    }
  }

  return (
    <>
      <div className='cbp-navbar'>
        <div className="app-container pt-2 pb-2">
          <Row>
            <Col md={8}>
              <div className="text-uppercase fs-2 displayInlineBlock">C.B.Patel Academy</div>
              <ButtonGroup size="sm" className='displayInlineBlock prod-selection'>
                <Button
                  variant="outline-primary"
                  active={selectedModule === MODULES.TURF}
                  onClick={() => {
                    setSelectedModule(MODULES.TURF);
                    navigate('/turf/booking');
                  }}
                >Turf</Button>
                <Button
                  variant="outline-primary"
                  active={selectedModule === MODULES.ACADEMY}
                  onClick={() => {
                    setSelectedModule(MODULES.ACADEMY);
                    navigate('/academy');
                  }}
                >Academy</Button>
                <Button
                  variant="outline-primary"
                  active={selectedModule === MODULES.MEMBER}
                  onClick={() => {
                    setSelectedModule(MODULES.MEMBER);
                    navigate('/members');
                  }}
                >Member</Button>
              </ButtonGroup>
            </Col>
            <Col className='text-end pt-1'>
              <label className="pt-2 px-3">Hi, {username}</label>
              <button type="button" className="btn btn-danger float-end" onClick={() => setLogoutDialog(!showLogoutDialog)}>Logout</button>
            </Col>
          </Row>
        </div>
      </div>
      <div className="app-container">
        <Routes>
          <Route path="/turf/booking" element={<TurfBooking />} />
          {/* <Route path="/" element={<TurfBooking />} /> */}
          <Route path="/academy" element={<Academy />} />
          <Route path="/members" element={<Members />} />
        </Routes>
      </div>
      <PromptDialog
        show={showLogoutDialog}
        handleClose={(resp) => {
          setLogoutDialog(!showLogoutDialog);
          if (resp) {
            logoutPrompt();
          }
        }}
        title="Logout"
        message="Are you sure, do you want to logout?"
      />
    </>
  );
};


export default React.memo(Container);
