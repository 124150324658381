import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

function PromptDialog({
  show,
  handleClose,
  title,
  message,
  yesText = 'Yes',
  noText = 'No',
}) {
  return (
    <Modal show={show} onHide={handleClose}>
      {
        title && (
          <Modal.Header closeButton>
            <Modal.Title>{title}</Modal.Title>
          </Modal.Header>
        )
      }
      <Modal.Body>{message}</Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={() => handleClose(false)}>
          {noText}
        </Button>
        <Button variant="primary" onClick={() => handleClose(true)}>
          {yesText}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default PromptDialog;