import { useEffect, useRef, useState } from 'react';
import { Alert, Badge, Col, Row } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import { FiPlusSquare, FiX } from 'react-icons/fi';
import AddBooker from './addBooker';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { find, get } from 'lodash';
import * as formik from 'formik';
import * as yup from 'yup';
import { SERVER_HIT } from '../util/API';
import { TURF_BOOKING_MESSAGE } from '../constants';
import { AiOutlineWhatsApp } from "react-icons/ai";

function AddTurfBooking({
  showModal,
  handleShow,
  addTurfBookingDetails
}) {
  const { memberList, turfHours } = useSelector((state) => state.academy)
  const [ showAddBookerModal, setAddBookerModal ] = useState(false);
  const [ selectedSlot, setSelectedSlot ] = useState(0);
  const [ selectedSlots, setSelectedSlots ] = useState([]);
  const [ showLoader, setLoader ] = useState(false);
  const [ errorMessage, setErrorMessage ] = useState('');
  const memberDetail = find(memberList, { member_id: addTurfBookingDetails?.member_id})

  const formRef = useRef()
  const { Formik } = formik;
  const turf_booking_id = get(addTurfBookingDetails, 'turf_booking_id', '');
  const schema = yup.object().shape({
    selectedMember: yup.string().required(),
    paymentReceived: yup.number().required().test({
      name: 'max',
      exclusive: false,
      params: {},
      message: 'Enter valid payment received.',
      test: function (value) {
        return parseFloat(value) <= parseFloat(this.parent.totalPayment)
      },
    }),
    totalPayment: yup.number().required().test({
      name: 'max',
      exclusive: false,
      params: {},
      message: 'Enter valid total payment.',
      test: function (value) {
        return parseFloat(value) >= parseFloat(this.parent.paymentReceived)
      },
    }),
    notes: yup.string(),
  });


  useEffect(() => {
    if (showModal) {
      setSelectedSlot(turf_booking_id !== '' ? addTurfBookingDetails.turf_hour_id : '');
      setSelectedSlots(turf_booking_id !== '' ? addTurfBookingDetails.turf_hour_id.split(',') : [addTurfBookingDetails.turf_hour_id]);
    } else {
      setSelectedSlot(0);
    }
    setErrorMessage('');
  }, [showModal])

  const getMemberBalance = async (member_id) => {
    const { status, data } = await SERVER_HIT.get(`?module=member&operation=get_available_balance_of_member&member_id=${member_id}&date=${addTurfBookingDetails.date}`, {});
    if (status === 200) {
      return data;
    }
    return {};
  }

  const handleSubmit = () => {
    if (formRef.current) {
      formRef.current.handleSubmit()
    }
  };

  const deleteBooking = async () => {
    setLoader(true);
    setErrorMessage('');
    const { status, data } = await SERVER_HIT.delete(`?module=turf&operation=delete_turf_bookings&turf_booking_id=${turf_booking_id}&group_code=${addTurfBookingDetails.group_code}`, {});
    if (status === 200) {
      if (data.message === 'Turf has been deleted') {
        setErrorMessage(data.message);
        setTimeout(() => {
          handleShow(true);
        }, 1000);
      } else {
        setErrorMessage(data.message);
      }
    }
    setLoader(false);
  }

  const getHour = (id) => get(find(turfHours, ({hour_id}) => hour_id == id), 'value', '');

  const prepareWhatsappMessage = (memberDetail, booking) => {
    const paymentReceived = get(booking, 'payment_received', 0);
    const totalPayment = get(booking, 'total_payment', 0);
    const hourIds = get(booking, 'turf_hour_id', '').split(',');
    var hours = '';
    if (hourIds.length === 1) {
      hours = get(turfHours.find(({ hour_id }) => hour_id === parseInt(hourIds[0])), 'value');
    } else {
      const startHour = get(turfHours.find(({ hour_id }) => parseInt(hour_id) === parseInt(hourIds[0])), 'value', ['','']);
      const endHour = get(turfHours.find(({ hour_id }) => parseInt(hour_id) === parseInt(hourIds[hourIds.length-1])), 'value', ['','']);
      hours = `${startHour.split('-')[0]} - ${endHour.split('-')[1]}`;
    }
    var message = TURF_BOOKING_MESSAGE;
    message = message.replace("{{NAME}}", get(memberDetail, 'member_name', ''))
    message = message.replace("{{MOBILE}}", get(memberDetail, 'contact_number', ''))
    message = message.replace("{{DATE}}", moment(get(booking, 'date', '')).format('DD-M-YY'))
    message = message.replace("{{TIME}}", hours)
    message = message.replace("{{TURF_NO}}", get(booking, 'turf_id', 'N/A'))
    message = message.replace("{{BOOKING_STATUS}}", 'CONFIRMED')
    message = message.replace("{{PAYMENT_STATUS}}", `${paymentReceived} RECEIVED / ${totalPayment-paymentReceived} PENDING`)
    return message;
  }
  
  return (
    <Modal show={showModal} onHide={handleShow} backdrop="static" keyboard={false}>
      <Modal.Header closeButton>
        <Modal.Title>{turf_booking_id !== '' ? 'Edit' : 'Add'} Turf Booking</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Formik
          enableReinitialize={true}
          innerRef={formRef}
          validationSchema={schema}
          onSubmit={async (values) => {
            setLoader(true);
            setErrorMessage('');
            let isSeq = true;
            for (var i = 1, len = values.selectedHours.length; i < len; i++) {
              if (parseInt(values.selectedHours[i]) !== parseInt(values.selectedHours[i - 1])+1) isSeq = false;
            }
            if (!isSeq) {
              setErrorMessage('Turf hours slots should be selected in sequence...');
            } else if (values.isMember && values.totalPayment > (values.availableHours * values.perHour)) {
              setErrorMessage(`You can not book more hours than balance (${values.availableHours * values.perHour})`);
            } else {
              const { status, data } = await SERVER_HIT.post(`?module=turf&operation=${get(addTurfBookingDetails, 'turf_booking_id', '') !== '' ? 'edit_turf_booking' : 'turf_booking'}`, {
                turf_id: addTurfBookingDetails.turf_id,
                member_id: values.selectedMember,
                turf_hour_id: values.selectedHours,
                date: addTurfBookingDetails.date,
                payment_received: values.paymentReceived,
                total_payment: values.totalPayment,
                note: values.notes,
                turf_booking_id: turf_booking_id
              });
              if (status === 200) {
                if (data.message === 'Turf has been booked.' || data.message === 'Turf booking has been updated') {
                  setErrorMessage(data.message);
                  setTimeout(() => {
                    handleShow(true);
                  }, 1000);
                } else {
                  setErrorMessage(data.message);
                }
              }
            }
            setLoader(false);
          }}
          initialValues={{
            selectedMember: get(addTurfBookingDetails, 'member_id', ''),
            availableHours: 0,
            perHour: 0,
            paymentReceived: get(addTurfBookingDetails, 'payment_received', ''),
            totalPayment: get(addTurfBookingDetails, 'total_payment', ''),
            selectedHours: [addTurfBookingDetails.turf_hour_id],
            notes: get(addTurfBookingDetails, 'note', ''),
            isMember: false,
            credit: 0,
            message: ''
          }}
        >
        {({ handleChange, values, setFieldValue, errors }) => (
          <Form>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Select Booker</Form.Label>
              <Row>
                <Col md={11}>
                  <Form.Select
                    name="selectedMember"
                    // value={selectedMember}
                    // onChange={(event) => setSelectedMember(event.target.value)}
                    required
                    value={values.selectedMember}
                    onChange={async (e) => {
                      handleChange('selectedMember')(e);
                      const membership = await getMemberBalance(e.currentTarget.value);
                      setFieldValue('isMember', get(membership, 'membership_exist', 0));
                      if (get(membership, 'membership_exist', 0) === 1) {
                        if (get(membership, 'available_balance', 0) === 0) {
                          setErrorMessage('You do not have balance.');
                        } else {
                          setFieldValue('availableHours', get(membership, 'available_balance', 0));
                          setFieldValue('perHour', get(membership, 'per_hour', 0));
                          setFieldValue('paymentReceived', 0);
                          setFieldValue('credit', get(membership, 'available_balance', 0));
                          setFieldValue('paymentReceived', values.selectedHours.length * get(membership, 'per_hour', 0));
                          setFieldValue('totalPayment', values.selectedHours.length * get(membership, 'per_hour', 0));
                        }
                      } else {
                        setFieldValue('message', get(membership, 'message', ''));
                        setFieldValue('availableHours', 0);
                        setFieldValue('perHour', 0);
                        setFieldValue('paymentReceived', 0);
                        setFieldValue('credit', 0);
                        setFieldValue('paymentReceived', 0);
                        setFieldValue('totalPayment', 0);
                      }
                    }}
                    isInvalid={!!errors.selectedMember}
                    disabled={turf_booking_id !== ''}
                  >
                    <option value="">Select Member</option>
                    {
                      memberList.map(({ member_id, member_name, contact_number }) => <option key={`member_${member_id}`} value={member_id}>{member_name} - {contact_number}</option>)
                    }
                  </Form.Select>
                </Col>
                <Col md={1} className='p-1'>
                  <FiPlusSquare className={`add-btn fs-4 ${turf_booking_id !== '' ? 'disabled' : ''}`} onClick={() => setAddBookerModal(true)} />
                </Col>
              </Row>
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Date</Form.Label>
              <Form.Control
                type="text"
                autoFocus
                disabled
                value={moment(addTurfBookingDetails.date).format("D MMMM YYYY")}
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>
                { turf_booking_id ? 'Booked Slots' : 'Available Slots'}
              </Form.Label>
              {
                !turf_booking_id && (
                  <>
                    <Form.Select aria-label="Default select example" value={selectedSlot} onChange={(event) => {
                      if (parseInt(event.target.value) !== parseInt(addTurfBookingDetails.turf_hour_id)) {
                        setSelectedSlot(event.target.value);
                        setSelectedSlots([
                          ...selectedSlots,
                          parseInt(event.target.value)
                        ]);
                        setFieldValue('selectedHours', [
                          ...selectedSlots,
                          parseInt(event.target.value)
                        ]);
                        if (values.isMember) {
                          const totalHours = [ ...selectedSlots, parseInt(event.target.value) ].length;
                          setFieldValue('paymentReceived', totalHours * values.perHour);
                          setFieldValue('totalPayment', totalHours * values.perHour);
                        }
                      }
                    }}
                    disabled={turf_booking_id !== ''}
                    >
                      <option value="0">Select Slot</option>
                      {
                        turfHours.map(({ hour_id, key, value }) => <option key={`hour_${key}`} value={hour_id}>{value}</option>)
                      }
                    </Form.Select>
                    <Form.Control.Feedback type="invalid">Please select slot.</Form.Control.Feedback>
                  </>    
                )
              }
              <div className='pt-2'>
                {
                  selectedSlots.map((slotId, index) => (
                    <Badge bg="primary" className={`mb-1 me-1 pt-2 ${index === 0 || turf_booking_id ? 'pb-2' : ''}`}>
                      {getHour(slotId)}
                      { index !== 0 && !turf_booking_id && (
                        <FiX
                          className='ms-1 pb-1 fs-5 cursor-pointer'
                          onClick={() => {
                            setSelectedSlots(selectedSlots.filter((slot) => slot !== slotId));
                            setFieldValue('selectedHours', selectedSlots.filter((slot) => slot !== slotId));
                            if (values.isMember) {
                              const totalHours = values.selectedHours.length - 1;
                              setFieldValue('paymentReceived', totalHours * values.perHour);
                              setFieldValue('totalPayment', totalHours * values.perHour);
                            }
                        }} />
                      )
                    }
                    </Badge>
                  ))
                }
              </div>
            </Form.Group>
            {
              !turf_booking_id && values.credit > 0 && (
                <Row>
                  <Col>
                    <Form.Group className="mb-3">
                      <Form.Label>Membership Credit</Form.Label>
                      {
                        values.message && (
                          <Alert variant={'danger'} className='p-2'>
                            {values.message}
                          </Alert>
                        )
                      }
                      {
                        values.credit > 0 && (
                          <Alert variant={'info'} className='p-2'>
                            Available Balance: {values.credit} Hours
                          </Alert>
                        )
                      }
                    </Form.Group>
                  </Col>
                </Row>
              )
            }
            {
              /*values.selectedMember && */(
                <Row>
                  <Col>
                    <Form.Group className="mb-3">
                      <Form.Label>Payment Received</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Payment Received"
                        name="paymentReceived"
                        autoFocus
                        value={values.paymentReceived}
                        onChange={handleChange}
                        isInvalid={!!errors.paymentReceived && !values.isMember}
                        disabled={values.isMember && values.availableHours > 0}
                      />
                      <Form.Control.Feedback type="invalid">{errors.paymentReceived}</Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group className="mb-3">
                      <Form.Label>Total Payment</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Total Payment"
                        name="totalPayment"
                        autoFocus
                        value={values.totalPayment}
                        onChange={(e) => {
                          handleChange('totalPayment')(e);
                          // if (values.credit > 0) {
                          //   setFieldValue('paymentReceived', values.credit > e.target.value ? e.target.value : values.credit);
                          // }
                        }}
                        isInvalid={!!errors.totalPayment && !values.isMember}
                        disabled={values.isMember && values.availableHours > 0}
                      />
                      <Form.Control.Feedback type="invalid">{errors.totalPayment}</Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                </Row>
              )
            }
            <Form.Group
              className="mb-3"
            >
              <Form.Label>Notes</Form.Label>
              <Form.Control as="textarea" rows={3} name="notes" value={values.notes} onChange={handleChange} isInvalid={!!errors.notes} />
            </Form.Group>
          </Form>
        )}
        </Formik>
        {
          errorMessage && (
            <Alert variant={'info'} className='p-2'>
              {errorMessage}
            </Alert>
          )
        }
        <AddBooker showModal={showAddBookerModal} hideModal={() => setAddBookerModal(false)}/>
      </Modal.Body>
      <Modal.Footer>
        {
          turf_booking_id && (
            <>
              <Button variant="success" onClick={() => window.open(`https://api.whatsapp.com/send?phone=91${get(memberDetail, 'contact_number', '')}&text=${prepareWhatsappMessage(memberDetail, addTurfBookingDetails)}`)} disabled={showLoader}>
                <AiOutlineWhatsApp size={20} style={{ verticalAlign: 'sub'}} />
              </Button>
              <Button variant="danger" onClick={deleteBooking} disabled={showLoader}>Delete</Button>
            </>
          )
        }
        <Button variant="primary" onClick={handleSubmit} disabled={showLoader}>
          { showLoader  ? 'Saving...' : 'Save Changes' }
        </Button>
        <Button variant="secondary" onClick={() => handleShow(false)} disabled={showLoader}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default AddTurfBooking;