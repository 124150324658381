import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  turfList: [],
  turfHours: [],
  memberList: [],
  standardList: [],
  schoolList: [],
  batchList: [],
  sportList: [],
}

export const academySlice = createSlice({
  name: 'academy',
  initialState,
  reducers: {
    setTurfList: (state, action) => {
      state.turfList = action.payload
    },
    setTurfHours: (state, action) => {
      state.turfHours = action.payload
    },
    setMemberList: (state, action) => {
      state.memberList = action.payload
    },
    setStandardList: (state, action) => {
      state.standardList = action.payload
    },
    setSchoolList: (state, action) => {
      state.schoolList = action.payload
    },
    setBatchList: (state, action) => {
      state.batchList = action.payload
    },
    setSportList: (state, action) => {
      state.sportList = action.payload
    },
  },
})

// Action creators are generated for each case reducer function
export const { setTurfList, setTurfHours, setMemberList, setStandardList, setSchoolList, setBatchList, setSportList } = academySlice.actions

export default academySlice.reducer