import React, { useEffect, useState } from "react";
import { Badge, Button, Col, Form, InputGroup, Row } from "react-bootstrap";
import { SERVER_HIT } from "../util/API";
import AddBooker from "../components/addBooker";
import PromptDialog from "../components/promptDialog";
import Membership from "../components/membership";
import moment from "moment";

const Members = () => {
  const [ members, setMembers ] = useState([]);
  const [ showAddBookerModal, setAddBookerModal ] = useState(false);
  const [ showAddMembershipModal, setAddMembershipModal ] = useState(false);
  const [ memberDetail, setMemberDetail ] = useState({
    member_id: '',
    member_name: '',
    contact_number: ''
  });
  const [ showDeleteDialog, setDeleteDialog ] = useState(false);
  const [ searchStudentName, setSearchStudentName ] = useState('');

  useEffect(() => {
    getMembers();
  }, []);

  const getMembers = async () => {
    const { status, data } = await SERVER_HIT.get('?module=member&operation=membership_list', {});
    if (status === 200) {
      setMembers(data);
    }
  }

  const deleteMember = async () => {
    const { status, data } = await SERVER_HIT.delete(`?module=member&operation=delete_member&member_id=${memberDetail.member_id}`, {});
    if (status === 200) {
      if (data.message === 'Data deleted') {
        getMembers();
      } else {
        alert(data.message)
      }
    }
  }

  return (
    <>
      <Row className="px-1 py-1">
        <Col className="col-8 fs-5 pt-1 fb">
          <b>Membership</b>
        </Col>

        <Col className="col-4 text-end pt-1 pb-1 d-flex">
          <InputGroup style={{ marginRight: '10px' }}>
            <Form.Control
              size="sm"
              placeholder="Search by Member Name"
              value={searchStudentName}
              onChange={(event) => setSearchStudentName(event.target.value)}
            />
            {/* <Button variant="outline-primary" size="sm" onClick={searchStudent}>
              Search
            </Button> */}
          </InputGroup>
          <Button
            style={{ width: '150px' }}
            size="sm"
            variant="outline-primary"
            onClick={() => {
              setAddBookerModal(true);
              setMemberDetail({
                member_id: '',
                member_name: '',
                contact_number: ''
              });
            }}
          >Add Member</Button>
        </Col>
      </Row>
      <div>
        <table className="table table-bordered table-hover">
          <thead>
            <tr>
              <th scope="col" width="12%" className="text-center">Member</th>
              <th scope="col" width="17%">Name</th>
              <th scope="col" width="13%">Contact Number</th>
              <th scope="col" width="10%" className="text-center">Membership</th>
              <th scope="col" width="20%" className="text-center">Duration</th>
              <th scope="col" width="18%" className="text-center">Available Hours / Total Hours</th>
            </tr>
          </thead>
          <tbody>
            {
              members.map((member) => {
                return (searchStudentName === '' || member.member_name.toLowerCase().indexOf(searchStudentName.toLowerCase()) > -1) ? (
                  <tr key={member.member_id}>
                    <td className="text-center">
                      <button
                        className="btn btn-outline-secondary btn-sm me-1"
                        type="button"
                        onClick={() => {
                          setAddBookerModal(true);
                          setMemberDetail({
                            member_id: member.member_id,
                            member_name: member.member_name,
                            contact_number: member.contact_number
                          })
                        }}
                      >
                        Edit
                      </button>

                      <button
                        className="btn btn-outline-secondary btn-sm ms-1" 
                        type="button"
                        onClick={() => {
                          setDeleteDialog(true);
                          setMemberDetail({
                            ...memberDetail,
                            member_id: member.member_id
                          })
                        }}
                      >
                        Delete
                      </button>
                    </td>
                    <td>
                      <a
                        className="text-decoration-underline pointer"
                        onClick={() => { 
                          setAddMembershipModal(true);
                          setMemberDetail({
                            member_id: member.member_id,
                            membership_id: member.membership_id,
                            start_date: member.start_date,
                            end_date: member.end_date,
                            total_credit: member.total_credit,
                            total_fees: member.total_fees,
                            member_name: member.member_name,
                            contact_number: member.contact_number,
                            paid_on: member.paid_on,
                            readOnly: true
                          })
                        }}
                      >
                        {member.member_name}
                      </a>
                    </td>
                    <td>{member.contact_number}</td>
                    <td className="text-center">
                      <button
                        className={`btn btn-sm me-1 ${member.is_member  === '1' ? 'btn-success' : 'btn-outline-secondary'}`}
                        type="button"
                        onClick={() => {
                          setAddMembershipModal(true);
                          setMemberDetail({
                            member_id: member.member_id,
                            membership_id: member.membership_id,
                            start_date: member.start_date,
                            end_date: member.end_date,
                            total_credit: member.total_credit,
                            total_fees: member.total_fees,
                            member_name: member.member_name,
                            contact_number: member.contact_number,
                            paid_on: member.paid_on,
                            readOnly: false
                          })
                        }}
                      >
                        { member.is_member === '1' ? 'Edit' : 'Add' }
                      </button>
                    </td>
                    <td className="text-center">
                      {
                        member.is_member === '1' && (
                          <>
                            <Badge className="btn-outline-secondary">
                              {moment(member.start_date).format("DD MMM, YYYY")}
                            </Badge>
                            &nbsp;To&nbsp;
                            <Badge>
                              {moment(member.end_date).format("DD MMM, YYYY")}
                            </Badge>
                          </>
                        )
                      }
                    </td>
                    <td className="text-center">
                      {
                        member.is_member === '1' && (
                          <>
                            {member.total_credit - member.used_credit} / {member.total_credit}
                          </>
                        )
                      }
                    </td>
                  </tr>
                ) : <></>
              })
            }
          </tbody>
        </table>
      </div>
      <AddBooker memberDetail={memberDetail} showModal={showAddBookerModal} hideModal={(resp = false) => {
        setAddBookerModal(false);
        if (resp) getMembers();
      }}/>
      <Membership memberDetail={memberDetail} showModal={showAddMembershipModal} hideModal={(resp = false) => {
        setAddMembershipModal(false);
        getMembers();
      }}/>
      <PromptDialog
        show={showDeleteDialog}
        handleClose={(resp) => {
          setDeleteDialog(!showDeleteDialog);
          if (resp) {
            deleteMember();
          }
        }}
        title="Delete Member"
        message="Do You want to delete member?"
      />
    </>
  );
};

export default Members;
