import { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import { SERVER_HIT } from '../util/API';
import { useDispatch } from 'react-redux';
import { setStandardList } from '../store/academySlice';

function AddStandard({
  showModal,
  hideModal
}) {
  const [ standardName, setStandardName ] = useState('');
  const [ loading, setLoading ] = useState(false);
  const [ validated, setValidated ] = useState(false);
  const [ errorMessage, setErrorMessage ] = useState('');
  const dispatch = useDispatch();

  useEffect(() => {
    if (showModal) {
      setStandardName('');
    }
  }, [ showModal ])

  const handleSubmit = async (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    } else {
      setErrorMessage('');
      setValidated(true);
      if (standardName) {
        setLoading(true);
        const { status, data } = await SERVER_HIT.post(`?module=academy&operation=add_standard`, {
          standard_name: standardName
        });
        setLoading(false);
        if (status === 200) {
          if (data.message === 'Data inserted') {
            const { status, data } = await SERVER_HIT.get('?module=academy&operation=standard_list', {});
            if (status === 200) {
              dispatch(setStandardList(data));
            }
            hideModal(true);
          } else {
            setErrorMessage(data.message);
          }
        }
      }
    }
  };

  return (
    <>
      <Modal show={showModal} onHide={hideModal} backdrop="static" keyboard={false}>
        <Modal.Header closeButton>
          <Modal.Title>Add Standard</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form validated={validated} onSubmit={handleSubmit}>
            <Form.Group className="mb-3">
              <Form.Label>Standard Name</Form.Label>
              <Form.Control
                type="text"
                autoFocus
                value={standardName}
                onChange={(event) => setStandardName(event.target.value)}
                required
              />
              <Form.Control.Feedback type="invalid">Please enter standard.</Form.Control.Feedback>
            </Form.Group>
            
            <div className='error text-center text-error'>
              {errorMessage}
            </div>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={hideModal}>
            Close
          </Button>
          <Button variant="primary" onClick={handleSubmit}>
            { loading ? 'Saving...' : 'Save'}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default AddStandard;