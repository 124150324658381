import { useRef, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import { SERVER_HIT } from '../util/API';
import { useSelector } from 'react-redux';
import { Alert, Col, Row } from 'react-bootstrap';
import * as formik from 'formik';
import * as yup from 'yup';
import { get } from 'lodash';
import AddSchool from './addSchool';
import AddStandard from './addStandard';
import moment from 'moment';
import { ACADEMY_MESSAGE, EMAIL_ID_REGEX, PHONE_REGEX } from '../constants';
import { AiOutlineWhatsApp } from 'react-icons/ai';

function AddAcademyStudent({
  studentDetail,
  showModal,
  hideModal
}) {
  const { sportList, batchList, schoolList, standardList } = useSelector((state) => state.academy);
  const [ showLoader, setLoader ] = useState(false);
  const [ errorMessage, setErrorMessage ] = useState('');
  const [ showAddSchoolModal, setAddSchoolModal ] = useState(false);
  const [ showAddStandardModal, setAddStandardModal ] = useState(false);
  const formRef = useRef()
  const { Formik } = formik;
  const schema = yup.object().shape({
    student_name: yup.string().required(),
    phone_number: yup.string().required().matches(PHONE_REGEX, 'Phone number is not valid'),
    whatsapp_mobile_number: yup.string().matches(PHONE_REGEX, 'Phone number is not valid'),
    email_address: yup.string().matches(EMAIL_ID_REGEX, 'Email Id is not valid'),
    // batch_id: yup.string().required(),
    school_id: yup.string().required(),
    standard_id: yup.string().required(),
    guardian_name: yup.string().required()
  });

  const handleSubmit = () => {
    if (formRef.current) {
      formRef.current.handleSubmit()
    }
  };
  const academy_student_id = get(studentDetail, 'academy_student_id', '');
  const readOnly = get(studentDetail, 'readOnly', false);

  const getBatchName = (batchId) => {
    return get(batchList.find(({batch_id}) => batch_id === batchId), 'batch_name', '');
  }
  
  const getSportName = (sportId) => {
    return get(sportList.find(({sport_id}) => sport_id === sportId), 'sport_name', '');
  }

  const prepareWhatsappMessage = () => {
    var message = ACADEMY_MESSAGE;
    message = message.replace("{{NAME}}", get(studentDetail, 'student_name', ''));
    message = message.replace("{{BATCH_TIME}}", getBatchName(get(studentDetail, 'batch_id', '')));
    message = message.replace("{{SPORT}}", getSportName(get(studentDetail, 'sport_id', '')));
    message = message.replace("{{DATE}}", `${moment(get(studentDetail, 'start_date', null)).format('DD-MM-YYYY')} to ${moment(get(studentDetail, 'end_date', null)).format('DD-MM-YYYY')}`);
    message = message.replace("{{FEES}}", get(studentDetail, 'amount_paid', ''));
    message = message.replace("{{PAID_DATE}}", moment(get(studentDetail, 'paid_on', '')).format('DD-MM-YYYY'));
    return message;
  }
  
  return (
    <>
      <Modal show={showModal} onHide={hideModal} backdrop="static" keyboard={false}>
        <Modal.Header closeButton>
          <Modal.Title>{academy_student_id !== '' ? (readOnly ? 'View' : 'Edit') : 'Add'} Academy Student</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Formik
            enableReinitialize={true}
            innerRef={formRef}
            validationSchema={schema}
            onSubmit={async (values) => {
              setLoader(true);
              setErrorMessage('');
              const { status, data } = await SERVER_HIT.post(`?module=academy&operation=${academy_student_id !== '' ? 'edit_academy_student' : 'add_academy_student'}`, {
                academy_student_id: academy_student_id,
                student_name: values.student_name,
                whatsapp_mobile_number: values.whatsapp_mobile_number,
                phone_number: values.phone_number,
                email_address: values.email_address,
                school_id: values.school_id,
                standard_id: values.standard_id,
                joining_date: values.joining_date,
                guardian_name: values.guardian_name,
                occupation: values.occupation,
                address: values.address,
                // batch_id: values.batch_id,
                batch_id: null,
              });
              if (status === 200) {
                if (data.message === 'Academy student added' || data.message === 'Academy student updated') {
                  setErrorMessage(data.message);
                  setTimeout(() => {
                    hideModal(true);
                    setErrorMessage('');
                  }, 1000);
                } else {
                  setErrorMessage(data.message);
                }
              }
              setLoader(false);
            }}
            initialValues={{
              student_name: get(studentDetail, 'student_name', ''),
              phone_number: get(studentDetail, 'phone_number', ''),
              whatsapp_mobile_number: get(studentDetail, 'whatsapp_mobile_number', ''),
              email_address: get(studentDetail, 'email_address', ''),
              batch_id: get(studentDetail, 'batch_id', ''),
              school_id: get(studentDetail, 'school_id', ''),
              standard_id: get(studentDetail, 'standard_id', ''),
              guardian_name: get(studentDetail, 'guardian_name', ''),
              occupation: get(studentDetail, 'occupation', ''),
              address: get(studentDetail, 'address', ''),
              joining_date: moment(get(studentDetail, 'joining_date', undefined)).format('YYYY-MM-DD')
            }}
          >
            {({ handleChange, values, setFieldValue, errors }) => (
              <Form>
                <Form.Group className="mb-3">
                  <Form.Label>Name</Form.Label>
                  <Form.Control
                    type="text"
                    autoFocus
                    name="student_name"
                    value={values.student_name}
                    onChange={handleChange}
                    required
                    isInvalid={!!errors.student_name}
                    disabled={readOnly}
                  />
                  <Form.Control.Feedback type="invalid">Please provide name.</Form.Control.Feedback>
                </Form.Group>
                <Row>
                  <Col>
                    <Form.Group className="mb-3">
                      <Form.Label>Contact Number</Form.Label>
                      <Form.Control
                        type="text"
                        required
                        autoFocus
                        name="phone_number"
                        value={values.phone_number}
                        onChange={handleChange}
                        isInvalid={!!errors.phone_number}
                        disabled={readOnly}
                      />
                      <Form.Control.Feedback type="invalid">Please provide contact number.</Form.Control.Feedback>
                    </Form.Group>
                  </Col>

                  <Col>
                    <Form.Group className="mb-3">
                      <Form.Label>Whatsapp Number</Form.Label>
                      <Form.Control
                        type="text"
                        required
                        autoFocus
                        name="whatsapp_mobile_number"
                        value={values.whatsapp_mobile_number}
                        onChange={handleChange}
                        isInvalid={!!errors.whatsapp_mobile_number}
                        disabled={readOnly}
                      />
                      <Form.Control.Feedback type="invalid">Please provide contact number.</Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                </Row>
                
                <Row>
                  <Col>
                    <Form.Group className="mb-3">
                      <Form.Label>Email Address</Form.Label>
                      <Form.Control
                        type="text"
                        required
                        autoFocus
                        name="email_address"
                        value={values.email_address}
                        onChange={handleChange}
                        isInvalid={!!errors.email_address}
                        disabled={readOnly}
                      />
                      <Form.Control.Feedback type="invalid">Please enter valid email id.</Form.Control.Feedback>
                    </Form.Group>
                  </Col>

                  {/* <Col>
                    <Form.Group className="mb-3">
                      <Form.Label>Batch</Form.Label>
                      <Form.Select
                        name="batch_id"
                        value={values.batch_id}
                        onChange={handleChange}
                        isInvalid={!!errors.batch_id}
                      >
                        <option value=''>Select Batch</option>
                        {
                          batchList.map(({ batch_id, batch_name }) => <option key={`batch_${batch_id}`} value={batch_id}>{batch_name}</option>)
                        }
                      </Form.Select>
                      <Form.Control.Feedback type="invalid">Please provide contact number.</Form.Control.Feedback>
                    </Form.Group>
                  </Col> */}
                </Row>

                <Row>
                  <Col>
                    <Form.Group className="mb-3">
                      <Form.Label>School</Form.Label>
                      <Form.Select
                        name="school_id"
                        value={values.school_id}
                        onChange={handleChange}
                        isInvalid={!!errors.school_id}
                        disabled={readOnly}
                      >
                        <option value=''>Select School</option>
                        {
                          schoolList.map(({ school_id, school_name }) => <option key={`school_${school_id}`} value={school_id}>{school_name}</option>)
                        }
                      </Form.Select>
                      {
                        !readOnly && <Button variant="link" className='float-right' onClick={() => setAddSchoolModal(true)}>Add School</Button>
                      }
                      <Form.Control.Feedback type="invalid">Please select school.</Form.Control.Feedback>
                    </Form.Group>
                  </Col>

                  <Col>
                    <Form.Group className="mb-3">
                      <Form.Label>Standard</Form.Label>
                      <Form.Select
                        name="standard_id"
                        value={values.standard_id}
                        onChange={handleChange}
                        isInvalid={!!errors.standard_id}
                        disabled={readOnly}
                      >
                        <option value=''>Select Standard</option>
                        {
                          standardList.map(({ standard_id, standard_name }) => <option key={`standard_${standard_id}`} value={standard_id}>{standard_name}</option>)
                        }
                      </Form.Select>
                      {
                        !readOnly && <Button variant="link" className='float-right' onClick={() => setAddStandardModal(true)}>Add Standard</Button>
                      }
                      <Form.Control.Feedback type="invalid">Please select standard.</Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                </Row>

                <Row>
                  <Col>
                    <Form.Group className="mb-3">
                      <Form.Label>Guardian Name</Form.Label>
                      <Form.Control
                        type="text"
                        required
                        autoFocus
                        name="guardian_name"
                        value={values.guardian_name}
                        onChange={handleChange}
                        isInvalid={!!errors.guardian_name}
                        disabled={readOnly}
                      />
                      <Form.Control.Feedback type="invalid">Please enter guardian name.</Form.Control.Feedback>
                    </Form.Group>
                  </Col>

                  <Col>
                    <Form.Group className="mb-3">
                      <Form.Label>Occupation</Form.Label>
                      <Form.Control
                        type="text"
                        required
                        autoFocus
                        name="occupation"
                        value={values.occupation}
                        onChange={handleChange}
                        disabled={readOnly}
                      />
                      <Form.Control.Feedback type="invalid">Please enter guardian occupation.</Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                </Row>

                <Form.Group className="mb-3">
                  <Form.Label>Address</Form.Label>
                  <textarea
                    className="form-control"
                    rows="3"
                    name="address"
                    value={values.address}
                    onChange={handleChange}
                    disabled={readOnly}
                  ></textarea>
                  <Form.Control.Feedback type="invalid">Please enter address.</Form.Control.Feedback>
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Label>Joining Date</Form.Label>
                  <Form.Control
                    type="date"
                    required
                    autoFocus
                    name="joining_date"
                    value={values.joining_date}
                    onChange={handleChange}
                    disabled={readOnly}
                  />
                  <Form.Control.Feedback type="invalid">Please enter address.</Form.Control.Feedback>
                </Form.Group>
              </Form>
            )}
          </Formik>
          {
            errorMessage && (
              <Alert variant={'info'} className='p-2'>
                {errorMessage}
              </Alert>
            )
          }
          <AddSchool showModal={showAddSchoolModal} hideModal={() => setAddSchoolModal(false)}/>
          <AddStandard showModal={showAddStandardModal} hideModal={() => setAddStandardModal(false)}/>
        </Modal.Body>
        <Modal.Footer>
          {
            get(studentDetail, 'whatsapp_mobile_number', '') && get(studentDetail, 'paid_on', '') !== '' && (
              <Button variant="success" onClick={() => window.open(`https://api.whatsapp.com/send?phone=91${get(studentDetail, 'whatsapp_mobile_number', '')}&text=${prepareWhatsappMessage()}`)} disabled={showLoader}>
                <AiOutlineWhatsApp size={20} style={{ verticalAlign: 'sub'}} />
              </Button>
            )
          }
          <Button variant="secondary" onClick={hideModal} disabled={showLoader}>
            Close
          </Button>
          {
            !readOnly && (
              <Button variant="primary" onClick={handleSubmit} disabled={showLoader}>
                { showLoader ? 'Saving...' : 'Save'}
              </Button>
            )
          }
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default AddAcademyStudent;